.refer_page {
  background-image: url("../../assets/refer_bg.png");
  background-position: center;
  background-size: cover;
  min-height: 70vh;
  background-repeat: no-repeat;
}
.diam_refer {
  width: 6rem;
}
.refer_field {
  width: 19rem;
  padding: 6px 2px 10px 2px;
  border: 1px solid rgba(245, 245, 245, 0.2);
  background: rgba(26, 26, 26, 0.47);
}
.refer_field input {
  outline: none;
}
.vertical_line {
  width: 0;
  height: 30px;
  border: 1px solid rgba(245, 245, 245, 0.6);
}
.inner_circle_refer {
  background-color: rgba(245, 245, 245, 0.6);
}
.arrow_icon_reward {
  width: 8px;
  border: 0.125rem solid rgba(245, 245, 245, 0.6);
  padding: 3px;
  rotate: 225deg;
  bottom: 0px;
  left: -4px;
  border-bottom: none;
  border-right: none;
  border-radius: 1px;
}

.copybtn {
  cursor: pointer;
  color: white;
font-size: 10px;
}