.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > li > a {
  border: none;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  background-color: inherit;
}
.pagination > li > a.modLink {
  background-color: inherit;
}

.pagination > li > a,
.pagination > li > span {
  color: #fff;
}

.pagination > li > a:hover {
  color: #fff;
  background-color: #555151;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 6px;
  background-color: #236dff;
  color: #fff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  opacity: 0.5;
  background-color: transparent;
  border: none;
}

li.page-item {
  margin: 0 4px;
}

.modPag {
  width: 5rem !important;
  transition: 0.5s;
}

.modPag.w-5 {
  width: 7rem !important;
  margin-right: 3.75rem !important;
}

.modPag.w-20 {
  width: 22rem !important;
}

.modItem {
  flex: 1 !important;
}

.modLink {
  text-align: center;
  border-radius: 2px !important;
}

#dashboardModalsT {
  margin: 0 70% !important;
}

#dashboardModals,
#dashboardModalsT {
  width: 100% !important;
}
.dashboardTransaction > #dashboardModalsT > .modal-content {
  width: 26vw;
  min-height: 100vh;
  overflow-y: auto;
  position: fixed;
  right: 0;
}

.buyBg {
  background-color: #0ad61e4d;
}
.sellbg {
  background-color: #b024244d;
}

.totalBg {
  background-color: #6c757d4d;
}

.dashboardTransaction::-webkit-scrollbar {
  width: 3px;
}
.dashboardTransaction::-webkit-scrollbar-thumb {
  background: #236dff;
  border-radius: 2px;
}

.perentageCircle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid green;
}

.fade-scale,
.dashboardTransaction {
  opacity: 0;
  -webkit-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transform: translateX(100%);
  transition: all 0.9s linear 0s;
}
.fade-scale.show,
.dashboardTransaction.show {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.trans_summ_wrap {
  background-color: rgb(26, 26, 26);
}

.pair_dropdown_spot .css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.timeDateBtn {
  width: 98%;
}
